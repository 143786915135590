import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Banner from "../components/Banner";
import CategoriesHorizontalList from "../components/CategoriesList";
import CategorySection from "../components/CategorySection";
import { getData } from "../services/products";
import { useNavigate } from "react-router-dom";

function Home() {
  
  const [isLoading,setLoading] = useState(true);
  const [firstStageConstruction, setFirstStageConstruction] = useState([]);
  const [secondStageConstruction, setSecondStageConstruction] = useState([]);
  const [thirdStageConstruction, setThirdStageConstruction] = useState([]);
  const [machinery, setMechinery] = useState([]);
  const [proffessionals, setProffessionals] = useState([]);
  const [technicalTools, setTechnicalTools] = useState([]);
  const [preFabricated, setPreFabricated] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProdcuts= async() => {
      const newData= await getData('products');
      console.log('newData:', newData);
      const firstStage = newData.filter((item) => item.englishCategory && item.englishCategory == "First stage of construction" ||  item.englishCategory == "1st stage of construction");
      const secondStage = newData.filter((item) => item.englishCategory && item.englishCategory == "Second stage of construction" ||  item.englishCategory == "2nd stage of construction");
      const thirdStage = newData.filter((item) => item.englishCategory && item.englishCategory == "Third stage of construction" ||  item.englishCategory == "3rd stage of construction");
      const machineryy = newData.filter((item) => item.englishCategory && item.englishCategory == "Machinery");
      const proffessionl = newData.filter((item) => item.englishCategory && item.englishCategory == "Professional" ||  item.englishCategory == "Professionals");
      const technicalToolss = newData.filter((item) => item.englishCategory && item.englishCategory == "Technical Tools");
      const preFabricatedd = newData.filter((item) => item.englishCategory && item.englishCategory == "Pre Febricated" ||  item.englishCategory == "PreFabricated");
      
      setFirstStageConstruction(firstStage);
      setSecondStageConstruction(secondStage);
      setThirdStageConstruction(thirdStage);
      setProffessionals(proffessionl);
      setMechinery(machineryy);
      setTechnicalTools(technicalToolss);
      setPreFabricated(preFabricatedd);
      setLoading(false);

    }
    fetchProdcuts();
  },[]);

  return (
    <div>
      <Banner />
      <p style={{maxWidth: 'fit-content', fontWeight:'bold', marginLeft:20}}>All Categories</p>
      <CategoriesHorizontalList activeCategory={0} setSelectedCategoryId={setSelectedCategoryId}/>     
      {isLoading ? 
            <CircularProgress color="success" sx={{margin: '24px'}}/>
            :
            <>
            <CategorySection products={firstStageConstruction} categoryName={'1st Stage Of Construction'}/>
            <CategorySection products={secondStageConstruction} categoryName={'2nd Stage Of Construction'}/>
            <CategorySection products={thirdStageConstruction} categoryName={'3rd Stage Of Construction'}/>
            <CategorySection products={machinery} categoryName={'Machinery'}/>
            <CategorySection products={proffessionals} categoryName={'Professionals'}/>
            <CategorySection products={technicalTools} categoryName={'Technical Tools'}/>
            <CategorySection products={preFabricated} categoryName={'PreFabricated'}/>
            </>
          }
    </div>
  );
}

export default Home;