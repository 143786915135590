import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { Card, CardMedia, CardContent, Typography, Grid, Box, Container } from "@mui/material";
import COLORS from "../constants/colors";
import Banner from "../components/Banner";
import CategoriesHorizontalList from "../components/CategoriesList";
import CategorySection from "../components/CategorySection";
import { getData } from "../services/products";


function CategoryPage() {
  const { categoryId } = useParams(); // If using React Router
  const navigate= useNavigate();
  console.log("CAtegory ID:", categoryId);
  const [selectedCategoryId, setSelectedCategoryId] = useState(categoryId || 1)
  const [isLoading,setLoading] = useState(true);
  const [allProductsData, setAllProductsData] = useState([]);
  const [filteredProductsData, setFilteredProductsData] = useState([]);

  const filterProductsCategoryBased = (categoryId) => {
        let newData = []; 
        if(categoryId == 1 ){
            newData = allProductsData.filter((item) => item.englishCategory && item.englishCategory == "First stage of construction" ||  item.englishCategory == "1st stage of construction");
        }else if(categoryId == 2){
            newData = allProductsData.filter((item) => item.englishCategory && item.englishCategory == "Second stage of construction" ||  item.englishCategory == "2nd stage of construction");
        }else if(categoryId == 3){
            newData = allProductsData.filter((item) => item.englishCategory && item.englishCategory == "Third stage of construction" ||  item.englishCategory == "3rd stage of construction");
        }else if(categoryId == 4){
            newData = allProductsData.filter((item) => item.englishCategory && item.englishCategory == "Machinery");
        }else if(categoryId == 5){
            newData = allProductsData.filter((item) => item.englishCategory && item.englishCategory == "Professional" ||  item.englishCategory == "Professionals");
        }else if(categoryId == 6){
            newData = allProductsData.filter((item) => item.englishCategory && item.englishCategory == "Technical Tools");
        }else if(categoryId == 7){
            newData = allProductsData.filter((item) => item.englishCategory && item.englishCategory == "Pre Febricated" ||  item.englishCategory == "PreFabricated");
        }
        setFilteredProductsData(newData);
  }

  useEffect(() => {
    const fetchProdcuts= async() => {
      const newData= await getData('products');
      setAllProductsData(newData);
      console.log('All Data fetched:')
      if(selectedCategoryId){
        console.log("In if sleectd cateroy", selectedCategoryId)
        filterProductsCategoryBased(selectedCategoryId);
      }
      setLoading(false);
    }
    fetchProdcuts();
  },[]);

  useEffect(() => {
    console.log("IS there:", )
    filterProductsCategoryBased(selectedCategoryId);
  },[selectedCategoryId, isLoading]);

  return (
    <div>
      <p style={{maxWidth: 'fit-content', fontWeight:'bold', marginLeft:20, marginTop: 165, cursor: 'pointer'}} onClick={()=>{navigate('/home')}}>All Categories</p>
      <CategoriesHorizontalList activeCategory={selectedCategoryId} setSelectedCategoryId={setSelectedCategoryId}/>     
      {isLoading ? 
            <CircularProgress color="success" sx={{margin: '24px'}}/>
            :
            <>
             <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container spacing={2} justifyContent={'center'}>
            {filteredProductsData.map((product) => (
            <div key={product.id} style={{maxWidth:'fit-content'}}>
                <Card sx={{ width: 280, height: 300, marginLeft: 2, marginRight: 2, marginBottom: 4, cursor:'pointer' }} onClick={() =>{navigate(`/product-detail/${product.id}`,  { scrollOptions: { top: 0 } })}}>
                <CardMedia component="img" height="140" image={product.thumbnail} alt={product.title} />
                <CardContent sx={{paddingBottom:'8px'}}>
                    <Typography gutterBottom variant="h6" component="div" color={COLORS.primaryColor} fontWeight={'bold'} width={'fit-content'}>
                    {product.title}
                    </Typography>
                    <Typography gutterBottom variant="body2" component="div" fontSize={18} color={COLORS.secondaryColor} width={'fit-content'} sx={{ maxHeight: '4em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    RS: {product.price}
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary" width={'fit-content'}>
                    
                    </Typography> */}
                </CardContent>
                </Card>
            </div>
        ))}
            </Grid>
            </Box>
         
            {/* <CategorySection products={firstStageConstruction} categoryName={'1st Stage Of Construction'}/>
            <CategorySection products={secondStageConstruction} categoryName={'2nd Stage Of Construction'}/>
            <CategorySection products={thirdStageConstruction} categoryName={'3rd Stage Of Construction'}/>
            <CategorySection products={machinery} categoryName={'Machinery'}/>
            <CategorySection products={proffessionals} categoryName={'Professionals'}/>
            <CategorySection products={technicalTools} categoryName={'Technical Tools'}/>
            <CategorySection products={preFabricated} categoryName={'PreFabricated'}/> */}
            </>
          }
    </div>
  );
}

export default CategoryPage;