const firstStageCategory = {
    title: 'First stage category',
    listItems: [
        { name: 'Brick', url: '/' },
        { name: 'Block', url: '/' },
        { name: 'Sand', url: '/' },
        { name: 'Gravel', url: '/' },
        { name: 'Stone', url: '/' },
        { name: 'Cement', url: '/' },
        { name: 'Concerete', url: '/' },
        { name: 'Steel', url: '/' },
        { name: 'Iron Rod', url: '/' },
        { name: 'Raw Wood', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Fibre reinforced Plastic', url: '/' },
        { name: 'Aggregate', url: '/' },
        { name: 'Mud', url: '/' }
    ]
}

const secondStageCategory = {
    title: 'Second stage category',
    listItems: [
        { name: 'Brick', url: '/' },
        { name: 'Block', url: '/' },
        { name: 'Sand', url: '/' },
        { name: 'Gravel', url: '/' },
        { name: 'Stone', url: '/' },
        { name: 'Cement', url: '/' },
        { name: 'Concerete', url: '/' },
        { name: 'Steel', url: '/' },
        { name: 'Iron Rod', url: '/' },
        { name: 'Raw Wood', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Fibre reinforced Plastic', url: '/' },
        { name: 'Aggregate', url: '/' },
        { name: 'Mud', url: '/' }
    ]
}

const thirdStageCategory = {
    title: 'Third stage category',
    listItems: [
        { name: 'Brick', url: '/' },
        { name: 'Block', url: '/' },
        { name: 'Sand', url: '/' },
        { name: 'Gravel', url: '/' },
        { name: 'Stone', url: '/' },
        { name: 'Cement', url: '/' },
        { name: 'Concerete', url: '/' },
        { name: 'Steel', url: '/' },
        { name: 'Iron Rod', url: '/' },
        { name: 'Raw Wood', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Fibre reinforced Plastic', url: '/' },
        { name: 'Aggregate', url: '/' },
        { name: 'Mud', url: '/' }
    ]
}

const machinery = {
    title: 'Machinery',
    listItems: [
        { name: 'Brick', url: '/' },
        { name: 'Block', url: '/' },
        { name: 'Sand', url: '/' },
        { name: 'Gravel', url: '/' },
        { name: 'Stone', url: '/' },
        { name: 'Cement', url: '/' },
        { name: 'Concerete', url: '/' },
        { name: 'Steel', url: '/' },
        { name: 'Iron Rod', url: '/' },
        { name: 'Raw Wood', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Fibre reinforced Plastic', url: '/' },
        { name: 'Aggregate', url: '/' },
        { name: 'Mud', url: '/' }
    ]
}


const professionals = {
    title: 'Professionals',
    listItems: [
        { name: 'Brick', url: '/' },
        { name: 'Block', url: '/' },
        { name: 'Sand', url: '/' },
        { name: 'Gravel', url: '/' },
        { name: 'Stone', url: '/' },
        { name: 'Cement', url: '/' },
        { name: 'Concerete', url: '/' },
        { name: 'Steel', url: '/' },
        { name: 'Iron Rod', url: '/' },
        { name: 'Raw Wood', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Fibre reinforced Plastic', url: '/' },
        { name: 'Aggregate', url: '/' },
        { name: 'Mud', url: '/' }
    ]
}

const technicalTools = {
    title: 'Technical tools',
    listItems: [
        { name: 'Brick', url: '/' },
        { name: 'Block', url: '/' },
        { name: 'Sand', url: '/' },
        { name: 'Gravel', url: '/' },
        { name: 'Stone', url: '/' },
        { name: 'Cement', url: '/' },
        { name: 'Concerete', url: '/' },
        { name: 'Steel', url: '/' },
        { name: 'Iron Rod', url: '/' },
        { name: 'Raw Wood', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Fibre reinforced Plastic', url: '/' },
        { name: 'Aggregate', url: '/' },
        { name: 'Mud', url: '/' }
    ]
}

const PreFabricated = {
    title: 'Pre Fabricated',
    listItems: [
        { name: 'Brick', url: '/' },
        { name: 'Block', url: '/' },
        { name: 'Sand', url: '/' },
        { name: 'Gravel', url: '/' },
        { name: 'Stone', url: '/' },
        { name: 'Cement', url: '/' },
        { name: 'Concerete', url: '/' },
        { name: 'Steel', url: '/' },
        { name: 'Iron Rod', url: '/' },
        { name: 'Raw Wood', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Shetring Sheet', url: '/' },
        { name: 'Fibre reinforced Plastic', url: '/' },
        { name: 'Aggregate', url: '/' },
        { name: 'Mud', url: '/' }
    ]
}



export { firstStageCategory, secondStageCategory, machinery, thirdStageCategory, professionals, technicalTools, PreFabricated }
