// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "@firebase/auth";
import { getStorage } from "firebase/storage";
import { setPersistence, browserSessionPersistence } from "@firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyDg7-K8clxNwBcndam2uMUgzQoK9Ytd-oA",
  authDomain: "mmpsites-58a5f.firebaseapp.com",
  projectId: "mmpsites-58a5f",
  storageBucket: "mmpsites-58a5f.appspot.com",
  messagingSenderId: "990577921888",
  appId: "1:990577921888:web:15b33c25078682a7e31805",
  measurementId: "G-W3P67L7SVJ"
};
// Initialize Firebase
export const db = initializeApp(firebaseConfig); // using firebaseconfig for staging
export const storage = getStorage(db);
export const firestore = getFirestore(db);
export const auth = getAuth(db);
setPersistence(auth, browserSessionPersistence);
