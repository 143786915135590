import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom'; // If using React Router
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

import SellerProfile from './SellerProfil';
import COLORS from '../../constants/colors';
import CategorySection from '../../components/CategorySection';
import { getProductData, getData } from '../../services/products';
import { getUserData } from '../../services/users';

const ProductDetailPage = () => {
  const { productId } = useParams(); // If using React Router
  const [isLoading,setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [userData, setUserData] = useState(null);
  const [products, setProducts] = useState([]);
  const [openImageGallery, setOpenImageGallery] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [currentImageIndex, setImageIndex] = useState(0);

  const handleGallery = (index) => {
    setOpenImageGallery(true);
    setImageIndex(index);
  };

  const closeGallery = () => {
    setOpenImageGallery(false);
    setImageIndex(0);
  };

  // Find the product based on productId (you can replace this with your own logic)
//   const product = products.find((product) => product.id === productId);
  // const product=   { id: 7, title: 'Product 7', description: 'Description for Product 7', image: '/images/product-image.png', seller: 'Ali' };


  useEffect(() => {
    setLoading(true);
    const fetchProduct= async() => {
      const newData= await getProductData('products', productId);
      console.log('newData:', newData)
      const userData1 = await getUserData(newData.addedBy);
      const productsData= await getData('products');
      console.log(newData);
      if(newData.images){
        const imagesArr= newData.images.map((image) => { return { src: image}})
        setProductImages(imagesArr);
      }
      setProduct(newData);
      setUserData(userData1);
      setLoading(false);
      const filterData = productsData.filter((item) => item.englishCategory === newData.englishCategory)
      setProducts(filterData);
      // await fetchProducts();
    }
    const fetchProducts= async() => {
      const newData= await getData('products');
      const filterData = newData.filter((item) => item.englishCategory === product.englishCategory)
      setProducts(filterData);
    }
    window.scrollTo(0, 0);
    fetchProduct();
    // fetchProducts();
  },[productId]);

  // if (!product) {
  //   return <Typography variant="h5">Product not found</Typography>;
  // }


  
  return (
    <div style={{minHeight:'30vh'}}>
      {isLoading ? <CircularProgress color="success" sx={{margin:'24px'}}/>
      :
    <>
    {product && 
    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around', padding: '16px'}}>
    <div style={{ display: 'flex', flexDirection: 'column', width:700}}>
      <Card style={{ width: '100%', marginBottom: '16px' }}>
        <CardMedia
          component="img"
          image={product.thumbnail}
          title={product.title}
          style={{ height: 400, objectFit: 'cover' }}
        />
      </Card>
      <Card style={{ width: '100%', marginBottom: '16px'}}>
        <CardContent>
        <Typography gutterBottom variant="h5" color={COLORS.primaryColor} component="div" width={'fit-content'} fontWeight={'bold'}>
          RS: {product.price}
          </Typography>
          <Typography gutterBottom variant="h4" component="div" width={'fit-content'}>
            {product.title}
          </Typography>
          <Typography gutterBottom variant="h6" component="div" width={'fit-content'}>
            {product.description}
          </Typography>
          {/* <Typography variant="body2" color="text.secondary" width={'fit-content'}>
            {product.description}
          </Typography> */}
        </CardContent>
      </Card>
      <Card style={{ width: '100%', marginBottom: '16px' }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" width={'fit-content'}>
            Product Images
          </Typography>
          {product.images && <div style={{ display: 'flex', flexWrap:'wrap', alignItems: 'center', marginTop: '16px' }}>
          <Carousel 
          images={productImages}
          index={currentImageIndex}
          objectFit="contain"
          objectFitAtMax="contain"
          playIcon={false}
          style={{ height: 500}} />
          {/* {product.images.map((img,index) => {
            return (
          <div style={{height:'90px', width:'80px', borderRadius:'10px', overflow:'clip', margin: '6px', cursor:'pointer'}} onClick={()=> {handleGallery(index)}}>
          <img alt={product.image} src={img} style={{objectFit:'contain'}} />
          </div>
            )
          })} */}
          
{/*           
          <div style={{height:'120px', width:'100px', borderRadius:'10px', overflow:'clip'}}>
          <img alt={product.image} src={product.image} />
          </div> */}
          {/* <Typography variant="subtitle1" component="div" style={{ marginLeft: '10px' }}>
          Seller: {product.seller} 
          </Typography>*/}
      </div>
          }
          
        </CardContent>
      </Card>
    </div>
    {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
        <Avatar alt={product.image} src={product.image} />
        <Typography variant="subtitle1" component="div" style={{ marginLeft: '10px' }}>
          Seller: {product.seller}
        </Typography>
      </div> */}
      <SellerProfile  user={userData}/>
    </div>
    }
    {products &&
    <div style={{marginLeft:16, marginRight: 16}}>
    <CategorySection products={products} categoryName={'Related Ads'}/>
    </div>
    }
     {openImageGallery && (
      <Carousel 
      images={productImages}
      index={currentImageIndex}
      style={{ height: 500, width: 800 }} />
      )}
    </>
    }
    </div>
  );
};

export default ProductDetailPage;
