import * as React from 'react';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardContent from '@mui/joy/CardContent';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import COLORS from '../constants/colors';
export default function PaymentsPage() {
    return (
        <Card
            variant="outlined"
            sx={{
                maxHeight: 'max-content',
                maxWidth: '50%',
                mx: 'auto',
                // to make the demo resizable
                overflow: 'auto',
                resize: 'horizontal',
                marginTop:'25px',
                marginBottom:'25px'

            }}
        >
            <Typography level="title-lg">
                Billing and payments
            </Typography>
            <Divider inset="none" />
            <CardContent
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, minmax(80px, 1fr))',
                    gap: 1.5,
                }}
            >
                <FormControl sx={{ gridColumn: '1/-1' }}>
                    <FormLabel>Card number</FormLabel>
                    <Input endDecorator={<CreditCardIcon />} placeholder='Enter your Card Number' type='number' />
                </FormControl>
                <FormControl>
                    <FormLabel>Expiry date</FormLabel>
                    <Input endDecorator={<CalendarTodayIcon />} placeholder='MM/YY' />
                </FormControl>
                <FormControl>
                    <FormLabel>CVC/CVV</FormLabel>
                    <Input placeholder='123' />
                </FormControl>
                <FormControl sx={{ gridColumn: '1/-1' }}>
                    <FormLabel>Card holder name</FormLabel>
                    <Input placeholder="Enter cardholder's full name" />
                </FormControl>
                <CardActions sx={{ gridColumn: '1/-1' }}>
                    <Button variant="solid" color="primary" sx={{ backgroundColor: '#027242', '&:hover': { 
                    backgroundColor: COLORS.primaryColor, 
                    }, }}>
                        PAY
                    </Button>
                </CardActions>
            </CardContent>
        </Card>
    );
}
