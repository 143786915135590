import * as React from 'react';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import COLORS from '../constants/colors';
import { toast } from 'react-toastify';
import { auth, firestore  } from '../config/firebase';
import { getUserData } from '../services/users';
import { signInWithEmailAndPassword} from 'firebase/auth';
import { AuthContext } from '../context/AuthContext';



const defaultTheme = createTheme();

export default function LoginPage() {
  
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const navigate= useNavigate();


  const handleChange = (e) => {
   setErrors({ email: '', password: ''})
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);
      const email = data.get('email');
      const password = data.get('password');
      // Validation
      let isValid = true;
      if (!email) {
        setErrors((prevErrors) => ({ ...prevErrors, email: 'Email is required' }));
        isValid = false;
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        setErrors((prevErrors) => ({ ...prevErrors, email: 'Invalid email address' }));
        isValid = false;
      }
      if (!password) {
        setErrors((prevErrors) => ({ ...prevErrors, password: 'Password is required' }));
        isValid = false;
      }
      if(isValid){
        setLoading(true);
        setErrors({ email: '', password: ''})
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        console.log("userCredential",userCredential);
        const { providerData, uid, accessToken } = userCredential.user;
        console.log('ProviderData:', providerData);
        console.log('uid:', uid);
        const userData= await getUserData(uid);
        console.log(userData);
        toast.success('Logged In Successfully!');
        setLoading(false);
        setIsAuthenticated(true);
        const storingObj= {accessToken: accessToken, ...userData }
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('userData', JSON.stringify(storingObj));
        navigate('/',  { replace: true });
      }
    } catch (error) {
      // let errorMessage = "";
      console.log('in error block');
      toast.error(error.code !== 404 ? error.code : error.message);
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: 'url(images/login_image.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'fit-content',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={6}>
          <Box
            sx={{
              my: 16,
              mx: 16,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: COLORS.primaryColor }}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              Log In
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={!!errors.email}
                onChange={handleChange}
                helperText={errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!errors.password}
                onChange={handleChange}
                helperText={errors.password}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled = {loading}
                sx={{ mt: 3, mb: 2 , bgcolor: COLORS.primaryColor, '&:hover': { // Override hover styles
                    backgroundColor: COLORS.primaryColor, // Set hover background color to transparent
                  }, height:'50px'}}
              >
                {loading ? (
              <CircularProgress
                size={24}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            ) : (
              'LOGIN'
            )}
            
              </Button>
              <Grid container>
                {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid> */}
                <Grid item>
                  <Link href="/signup-type" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}