import React, { useState, useRef, useEffect } from 'react';
import { firstStageCategory, secondStageCategory, thirdStageCategory, machinery, technicalTools, professionals, PreFabricated } from './categories';
import { arrowDown, arrowUp } from '../../svg/svg';
import { firstStageList, secondStageList, thirdStageList, machineryList, professionList, techniqalTollsList, preFebricatedList } from '../../DataSet';

const AllCategoriesNav = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [select, setSelect] = useState('');
    const modalRef = useRef(null);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const saveCategory = (category) => {
        setSelect(category);
        toggleModal()
    }

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div style={{marginTop:150}}>
            <div style={{ display: 'flex', alignItems: 'center', width: 'fit-content', justifyContent: 'space-between', gap: '43px', marginLeft: '24px', zIndex: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={toggleModal}> <h3>All Categories</h3>{isOpen ? arrowUp() : arrowDown()}</div>
                <div style={{ gap: '6px', cursor: 'pointer' }}>1st Stage Construction</div>
                <div style={{ gap: '6px', cursor: 'pointer' }}>2nd Stage Construction</div>
                <div style={{ gap: '6px', cursor: 'pointer' }}>3rd Stage Construction</div>
                <div style={{ gap: '6px', cursor: 'pointer' }}>Machinery</div>
                <div style={{ gap: '6px', cursor: 'pointer' }}>Professionals</div>
                <div style={{ gap: '6px', cursor: 'pointer' }}>Technical Tools</div>
                <div style={{ gap: '6px', cursor: 'pointer' }}>Pre-Fabricated</div>
            </div>

            {
                isOpen && (<div className='Model' ref={modalRef}>
                    <div style={{ display: 'flex', gap: '8%', width: '100%', backgroundColor: 'white', marginBottom: '10px', flexWrap: 'wrap', paddingTop: '16px', paddingBottom:'16px'}}>
                        <Category title={firstStageCategory.title} listItems={firstStageList} toggleModal={toggleModal} saveCategory={saveCategory} />
                        <Category title={secondStageCategory.title} listItems={secondStageList} toggleModal={toggleModal} saveCategory={saveCategory} />
                        <Category title={thirdStageCategory.title} listItems={thirdStageList} toggleModal={toggleModal} saveCategory={saveCategory} />
                        <Category title={machinery.title} listItems={machineryList} toggleModal={toggleModal} saveCategory={saveCategory} />
                        <Category title={technicalTools.title} listItems={techniqalTollsList} toggleModal={toggleModal} saveCategory={saveCategory} />
                        <Category title={professionals.title} listItems={professionList} toggleModal={toggleModal} saveCategory={saveCategory} />
                        <Category title={PreFabricated.title} listItems={preFebricatedList} toggleModal={toggleModal} saveCategory={saveCategory} />

                    </div>
                </div>)
            }
        </div >
    );
};


const Category = ({ title, listItems, toggleModal, saveCategory }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "20px", marginLeft:'24px' }}>
            <div style={{ fontWeight: 600, fontSize: '20px', marginBottom: "10px", width:'fit-content' }}>{title}</div>
            {listItems.map((item, index) => (
                <div key={index} style={{ gap: '10px', cursor: 'pointer', width: 'fit-content', marginTop: '2px' }} onClick={() => saveCategory(item)}>{item}</div>
            ))
            }

            {/* <div style={{ gap: '10px' }}>Brick</div>
            <div style={{ gap: '10px' }}>Block</div>
            <div style={{ gap: '10px' }}>Sand</div>
            <div style={{ gap: '10px' }}>Gravel</div>
            <div style={{ gap: '10px' }}>Stone</div>
            <div style={{ gap: '10px' }}>Cement</div>
            <div style={{ gap: '10px' }}>Concerete</div>
            <div style={{ gap: '10px' }}>Steel</div>
            <div style={{ gap: '10px' }}>Iron Rod</div>
            <div style={{ gap: '10px' }}>Iron Pipes</div>
            <div style={{ gap: '10px' }}>Raw Wood</div>
            <div style={{ gap: '10px' }}>Shetring Sheet</div>
            <div style={{ gap: '10px' }}>Fibre reinforced Plastic</div>
            <div style={{ gap: '10px' }}>Aggregate</div>
            <div style={{ gap: '10px' }}>Mud</div> */}
        </div >
    )
}
export default AllCategoriesNav;
