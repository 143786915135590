// import React from 'react'

// const Column = () => {
//     return (
//         <div>
//             <div style={{ fontWeight: 800, lineHeight: '29.29px', fontSize: '24.2px' }}>Popular Category</div>
//             <div style={{ gap: '9.86px', height: '24px', width: '131px' }}>First Stage</div>
//             <div style={{ gap: '9.86px', height: '24px', width: '131px' }}>Second Stage</div>
//             <div style={{ gap: '9.86px', height: '24px', width: '131px' }}>Third Stage</div>
//             <div style={{ gap: '9.86px', height: '24px', width: '131px' }}>Machinery</div>
//             <div style={{ gap: '9.86px', height: '24px', width: '131px' }}>Professionals</div>
//             <div style={{ gap: '9.86px', height: '24px', width: '131px' }}>Technical Tools</div>
//             <div style={{ gap: '9.86px', height: '24px', width: '131px' }}>Pre Fabricated</div>
//         </div>
//     )
// }

// export default Column

import React from 'react';

const Column = ({ title, listItems }) => {
    return (
        <div>
            <div style={{ fontWeight: 600, fontSize: 20, marginBottom: '15px', width:'fit-content' }}>{title}</div>
            {listItems.map((item, index) => (
                <div key={index} style={{ gap: '9.86px', width: 'fit-content', cursor: 'pointer' }}><p style={{marginTop:'6px', marginBottom:'6px'}}>{item}</p></div>
            ))}
        </div>
    );
}

export default Column;
