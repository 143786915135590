import React from 'react';
import { useParams } from 'react-router-dom'; // If using React Router
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import COLORS from '../../constants/colors';

const SellerProfile = ({ user }) => {
//   const { productId } = useParams(); // If using React Router

  // Find the product based on productId (you can replace this with your own logic)
//   const product = products.find((product) => product.id === productId);
  const product=   { id: 7, title: 'Product 7', description: 'Description for Product 7', image: '/images/product-image.png', seller: 'Ali', location: 'Johar Town, Lahore' };


  return (
      <div>
      <Card style={{ maxWidth: 400, minWidth: 300, marginTop: 48}}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" fontWeight={'bold'}>
            Seller Profile
          </Typography>
          <Typography gutterBottom variant="h6" component="div" width={'fit-content'}>
            Name: {user.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" width={'fit-content'}>
            Location: {user.location}
          </Typography>
          <div style={{display:'flex', flexDirection:'column'}}>
          <Button variant="contained" style={{ marginTop: '16px', background:COLORS.primaryColor }}>
            Get Location
          </Button>
          <Button variant="contained" style={{ marginTop: '16px', background:COLORS.primaryColor }}>
            Chat
          </Button>
          </div>
        </CardContent>
      </Card>
    {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
        <Avatar alt={product.image} src={product.image} />
        <Typography variant="subtitle1" component="div" style={{ marginLeft: '10px' }}>
          Seller: {product.seller}
        </Typography>
      </div> */}
    </div>
  );
};

export default SellerProfile;
