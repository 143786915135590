import { firestore } from "../config/firebase";
import { collection, getDocs, orderBy, query,where, doc, getDoc } from "@firebase/firestore";


export const getUserData = async (
  id='null',
) => {
  try{
    const docRef = doc(firestore , 'users', id);
    console.log('id:', id);
    const docSnap = await getDoc(docRef);
    console.log('docSnap',docSnap);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return { id: docSnap.id, ...docSnap.data() }
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      throw { code: 404, message: "User Not Found" };
    }

  }catch(err){
    throw err;
  }
};

// async function getUserData(uid) {
//     try {
//       const userRef = doc(firestore, "users", uid);
//       const userDoc = await getDoc(userRef);
//       if (userDoc.exists()) {
//         return { id: userDoc.id, ...userDoc.data() };
//       } else {
//         throw { code: 404, message: "User Not Found" };
//       }
//     } catch (error) {
//       throw error;
//     }
//   }