// SvgComponents.js
import React from 'react';

const facebook = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_9_307)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12C4.00014 10.4605 4.44451 8.95364 5.27979 7.66038C6.11506 6.36712 7.30576 5.34234 8.70901 4.70901C10.1123 4.07568 11.6684 3.8607 13.1908 4.08987C14.7132 4.31904 16.1372 4.98262 17.2918 6.00099C18.4464 7.01936 19.2826 8.34926 19.7002 9.83111C20.1177 11.313 20.0988 12.8838 19.6457 14.3552C19.1926 15.8265 18.3246 17.1359 17.1458 18.1262C15.967 19.1165 14.5275 19.7456 13 19.938V14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13C16 12.7348 15.8946 12.4804 15.7071 12.2929C15.5196 12.1054 15.2652 12 15 12H13V10C13 9.73478 13.1054 9.48043 13.2929 9.29289C13.4804 9.10536 13.7348 9 14 9H14.5C14.7652 9 15.0196 8.89464 15.2071 8.70711C15.3946 8.51957 15.5 8.26522 15.5 8C15.5 7.73478 15.3946 7.48043 15.2071 7.29289C15.0196 7.10536 14.7652 7 14.5 7H14C13.2044 7 12.4413 7.31607 11.8787 7.87868C11.3161 8.44129 11 9.20435 11 10V12H9C8.73478 12 8.48043 12.1054 8.29289 12.2929C8.10536 12.4804 8 12.7348 8 13C8 13.2652 8.10536 13.5196 8.29289 13.7071C8.48043 13.8946 8.73478 14 9 14H11V19.938C9.0667 19.6942 7.28882 18.7533 6 17.2917C4.71119 15.8302 4.00003 13.9486 4 12ZM12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_9_307">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

const instagram = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.5 6.5H17.51M16 11.37C16.1234 12.2022 15.9813 13.0522 15.5938 13.799C15.2063 14.5458 14.5932 15.1514 13.8416 15.5297C13.0901 15.9079 12.2385 16.0396 11.4078 15.9059C10.5771 15.7723 9.80977 15.3801 9.21485 14.7852C8.61993 14.1902 8.22774 13.4229 8.09408 12.5922C7.96042 11.7616 8.09208 10.9099 8.47034 10.1584C8.8486 9.40685 9.4542 8.79374 10.201 8.40624C10.9478 8.01874 11.7978 7.87659 12.63 8C13.4789 8.12588 14.2649 8.52146 14.8717 9.12831C15.4785 9.73515 15.8741 10.5211 16 11.37Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

const twitter = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.35 5.55011C14.59 5.54999 13.8602 5.84824 13.3179 6.38068C12.7755 6.91313 12.4639 7.63721 12.45 8.39711L12.422 9.97211C12.4203 10.0567 12.4008 10.1399 12.3648 10.2164C12.3287 10.2929 12.2768 10.3609 12.2126 10.416C12.1484 10.471 12.0733 10.5119 11.9922 10.5359C11.9111 10.5599 11.8258 10.5664 11.742 10.5551L10.18 10.3431C8.12698 10.0631 6.15898 9.11711 4.26998 7.54411C3.67298 10.8541 4.83998 13.1471 7.65298 14.9161L9.39998 16.0141C9.48305 16.0662 9.55209 16.1378 9.60106 16.2228C9.65003 16.3077 9.67744 16.4033 9.6809 16.5013C9.68435 16.5993 9.66374 16.6966 9.62087 16.7848C9.578 16.873 9.51418 16.9493 9.43498 17.0071L7.84298 18.1701C8.78998 18.2291 9.68898 18.1871 10.435 18.0391C15.153 17.0971 18.29 13.5471 18.29 7.69111C18.29 7.21311 17.277 5.55011 15.35 5.55011ZM10.45 8.36011C10.4674 7.39616 10.7689 6.45878 11.3165 5.66534C11.8642 4.87189 12.6338 4.25763 13.5289 3.89948C14.424 3.54133 15.4049 3.45519 16.3487 3.65186C17.2926 3.84853 18.1574 4.31926 18.835 5.00511C19.546 5.00011 20.151 5.18011 21.503 4.36011C21.169 6.00011 21.003 6.71211 20.29 7.69111C20.29 15.3331 15.593 19.0491 10.826 20.0001C7.55898 20.6521 2.80598 19.5811 1.44598 18.1591C2.13898 18.1051 4.95898 17.8021 6.58898 16.6091C5.20898 15.6991 -0.279016 12.4691 3.32798 3.78611C5.02098 5.76311 6.73798 7.10911 8.47798 7.82311C9.63498 8.29811 9.91998 8.28911 10.451 8.36111" fill="white" />
    </svg>
}


const arrowUp = () => <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 11.25L20.3037 16.5525L18.535 18.32L15 14.785L11.465 18.32L9.69621 16.5525L15 11.25Z" fill="black" />
</svg>

const arrowDown = () => <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 18.75L9.69629 13.4475L11.465 11.68L15 15.215L18.535 11.68L20.3038 13.4475L15 18.75Z" fill="black" />
</svg>

export {
    facebook,
    instagram, twitter, arrowDown, arrowUp
}