import PATHS from '../constants/paths';
import Home from "../pages/Home";
import { Navigate, Route, Routes } from "react-router-dom";
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import ProtectedRoute from './ProtectedRoute';
import ProductDetailPage from '../pages/ProductDetail';
import ActivePlanPage from '../pages/ActivePlan';
import SubscribePlan from '../pages/SubscribePlan';
import PaymentsPage from '../pages/Payments';
import PostAd from '../pages/PostAd';
import ResponsiveAppBar from '../components/NavBar';
import BottomNavigationBar from '../components/BottomNavigation';
import AllCategoriesNav from '../components/AllCategoriesNav';import LoginPage from '../pages/Login';
import Header from '../components/Header';
import SingupType from '../pages/signupType';
import SingupBuyerPage from '../pages/signupBuyer';
import SingupSellerPage from '../pages/signupSeller';
import CategoryPage from '../pages/CategoryPage';
import Footer from '../components/Footer';

const AppRoutes = () => {
  const { loading } = useContext(AuthContext);
  return (
    <>
    {loading ? (
        <div>Loading...</div>
      ) : (
        <>
        <Routes>
          <Route path={PATHS.SIGN_IN} element={<><Header/><LoginPage /></>}/>
          <Route path={PATHS.SIGNUP_TYPE} element={<><Header/><SingupType /></>} />
          <Route path={PATHS.SIGN_UP} element={<><Header/><SingupBuyerPage /></>} />
          <Route path={PATHS.SIGNUP_SELLER} element={<><Header/><SingupSellerPage /></>} />
          <Route path={PATHS.HOME} element={<><ResponsiveAppBar/><BottomNavigationBar/><AllCategoriesNav/><Home /><Footer/></>} />
          <Route path={PATHS.CATEGORY_PAGE} element={<><ResponsiveAppBar/><BottomNavigationBar/><CategoryPage /><Footer/></>} />
          <Route path={PATHS.PRODUCT_DETAIL} element={<><ResponsiveAppBar/><BottomNavigationBar/><AllCategoriesNav/><ProductDetailPage /><Footer/></>} />
          <Route element={<ProtectedRoute />}>
            <Route path={PATHS.ACTIVE_PLAN} element={<ActivePlanPage />} />
            <Route path={PATHS.SUBSCRIPTION} element={<SubscribePlan />} />
            <Route path={PATHS.PAYMENT} element={<PaymentsPage />} />
            <Route path={PATHS.POST_AD} element={<PostAd />} />
          </Route>
          <Route path="*" element={<Navigate to={PATHS.HOME} replace />} />
        </Routes>
        </>
      )}
    </>
  );
};

export default AppRoutes;