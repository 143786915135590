import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Divider } from '@mui/material';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PaymentIcon from '@mui/icons-material/Payment';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

import COLORS from '../constants/colors';

const pages = ['Home', 'About Us', 'Contact Us'];
const settings = [{name: 'Profile'}, {name: 'Account'}, {name: 'Dashboard'}, {name: 'Logout'}];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate= useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDropdownMenu =(menu) => {
    navigate(menu);
  }

  return (
    <AppBar position="fixed">
      <Container maxWidth="xxl" sx={{ backgroundColor: COLORS.primaryColor, paddingTop: '5px', paddingBottom: '5px' }}>
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <img src="/app-logo.png" alt="MMP" width={52} height={60} />
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              ml: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            MMPSites
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;