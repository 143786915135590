import React from 'react'
import Column from './../Column'
import { facebook, instagram, twitter } from '../../svg/svg'

const popularCategories = {
    title: 'Popular Category',
    listItems: ['First Stage', 'Second Stage', 'Third Stage', 'Machinery', 'Professionals', 'Technical Tools', 'Pre Fabricated']
};


const trendingCategories = {
    title: 'Trending Category',
    listItems: ['Machinery', 'Professionals', 'Technical Tools', 'Pre Fabricated']

}

const aboutUs = {
    title: 'About Us',
    listItems: ['First Stage', 'Second Stage', 'Third Stage', 'Machinery', 'Professionals', 'Technical Tools', 'Pre Fabricated']
};


const Footer = () => {
    return (
        <div style={{
        backgroundColor: 'black', color: 'white', width: '100%', height: 'fit-content', opacity: '0px', display: 'flex', justifyContent:'space-around', background: '#232323',paddingTop: '25px', paddingBottom:'25px'
        }}>
            <div className='footerInfo' style={{ display: 'flex', gap: '50px', marginLeft: '25px'}}>
                <Column title={popularCategories.title} listItems={popularCategories.listItems} />
                <Column title={trendingCategories.title} listItems={trendingCategories.listItems} />
                <Column title={aboutUs.title} listItems={aboutUs.listItems} />
                {/* <Column title={popularCategories.title} listItems={popularCategories.listItems} /> */}
            </div>
            <div className='socialLinks' style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontWeight: 600, fontSize: '20px', marginBottom: '15px' ,width:'fit-content'}}>Follow Us</div>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <a href="https://www.facebook.com/people/MMP-Sites/61550353949095/" target='blank'>{facebook()}</a>
                    <a href="https://www.instagram.com/invites/contact/?i=fkzx4ycv9slt&utm_content=uo24ovc" target='blank'>{instagram()}</a>
                    {twitter()}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '5px', marginTop: '10px' }}>
                    <a href='https://play.google.com/store/apps/details?id=com.mmpsites&pcampaignid=web_share' target='blank'>
                    <img src="/playStore.png" alt="" width={160} height={55}/></a>
                    <a href='https://apps.apple.com/pk/app/mmpsites/id6447799556' target='blank'>
                    <img src="/appleStore.png" alt="" width={160} height={60} />
                    </a>

                </div>
            </div>

        </div>
    )
}




export default Footer