import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import COLORS from '../constants/colors';
import { useNavigate } from 'react-router-dom';



const defaultTheme = createTheme();

export default function SingupType() {
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: 'url(images/login_image.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'fit-content',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={6}>
          <Box
            sx={{
              my: 24,
              mx: 16,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Button
                type="submit"
                onClick={()=>navigate('/signup')}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 , bgcolor: COLORS.primaryColor, '&:hover': { // Override hover styles
                    backgroundColor: COLORS.primaryColor, // Set hover background color to transparent
                  }, height:'50px'}}
              >
                SIGNUP AS BUYER
              </Button>
              <Button
                type="submit"
                onClick={()=>navigate('/signup-seller')}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 , bgcolor: COLORS.primaryColor, '&:hover': { // Override hover styles
                    backgroundColor: COLORS.primaryColor, // Set hover background color to transparent
                  }, height:'50px'}}
              >
              SIGNUP AS SELLER
              </Button>
              <Grid container>
                {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid> */}
                <Grid item>
                  <Link href="/signin" variant="body2">
                    {"Already have account? Login"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}