import React from "react";
import Routes from "./routes";
import PublicRoutes from "./publicRoutes";
import { AuthProvider } from "../context/AuthContext";
const MainRoutes = () => {

  return (
    <React.Fragment>
        {/* <PublicRoutes/> */}
        <AuthProvider>
        <Routes />
        </AuthProvider>
    </React.Fragment>
  );
};

export default MainRoutes;
