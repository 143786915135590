import { firestore } from "../config/firebase";
import { collection, getDocs, orderBy, query,where, doc, getDoc, addDoc } from "@firebase/firestore";


//? ======================== get data from firebase coll receive paramter col name condition  ========================
// const getData1 = async (
//     collection,
//     condition = false,
//     value = 'non',
//     operator = '==',
//     equalTo = 'non',
//   ) => {
//     let list = [];
//     if (condition) {
//       let querySnapshot = await firestore()
//         .collection(collection)
//         .where(value, operator, equalTo)
//         .get();
//       querySnapshot.docs.map(doc => {
//         list.push({...doc.data(), id: doc.id});
//       });
//     } else {
//       let querySnapshot = await firestore().collection(collection).get();
//       querySnapshot.docs.map(doc => {
//         list.push({...doc.data(), id: doc.id});
//       });
//     }
  
//     return list;
//   };


export const getProductData = async (
  collection1,
  id='null',
) => {
  const docRef = doc(firestore , collection1, id);
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return {
      ...docSnap.data()
    }
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    return ;
  }
};





export const getData = async (
    collection1,
    condition = false,
    value = 'non',
    operator = '==',
    equalTo = 'non',
  ) => {
    let list = [];
    if (condition) {
      console.log(' --->:', condition, value, operator, equalTo)
      const q = query(collection(firestore, collection1), where(value, operator, equalTo));
      // const document_ref = collection(firestore, collection1);
      // const match_query = query(document_ref, where(value, operator, "3ncRSokqYf0OB6a4KIsm"));
      let querySnapshot = await getDocs(q);
      querySnapshot.docs.map(doc => {
       list.push({...doc.data(), id: doc.id});
      });
      console.log('list:', list)
    } else {
      const document_ref = collection(firestore, collection1);
      const user_query = query(document_ref);
      let querySnapshot = await getDocs(user_query); ;
       querySnapshot.docs.map(doc => {
        list.push({...doc.data(), id: doc.id});
      });
      console.log('list[0]', list[0])
    }
    return list;
  };

  export const postData = async (collection1, data) => {
    const docRef = await addDoc(collection(firestore, collection1), data);
    console.log("Document written with ID: ", docRef.id);
  };

