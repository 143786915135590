import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ButtonGroup from '@mui/material/ButtonGroup';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormControl } from '@mui/material';


import COLORS from '../constants/colors';
import { allCities } from '../DataSet';


function BottomNavigationBar() {

    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userData')));
    const navigate = useNavigate();
    console.log('User Data:', userData);


  return (
    <AppBar position="fixed" style={{top:76}}>
      <Container maxWidth="xxl" sx={{ backgroundColor: COLORS.whiteColor, padding: 1 }}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, justifyContent:'space-evenly', display: { xs: 'none', md: 'flex' } }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={allCities}
            sx={{ width: 300 }}
            // renderInput={(params) => <TextField {...params} label="Movie" />}
            renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <React.Fragment>
                      <LocationOnIcon sx={{ marginRight: '8px' }}/>
                      Location
                    </React.Fragment>
                  }
                />
              )}
            />
        <div>
        <FormControl sx={{ width: '45ch' }} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            placeholder='Search'
            // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            sx={{borderEndEndRadius:0, borderStartEndRadius:0}}
          />
          </FormControl>
          <Button sx={{background:COLORS.primaryColor, height: '57px',borderStartStartRadius:0, borderEndStartRadius:0,  '&:hover': { // Override hover styles
          backgroundColor: COLORS.primaryColor, // Set hover background color to transparent
        },}} variant='filled'>
          <SearchIcon color='white'/>
          </Button>
          </div>
          <Button variant="text" sx={{color:COLORS.primaryColor, visibility: userData ? 'hidden' : 'visible'}} onClick={() => navigate('/signin') }>Login</Button>
          <Button variant="outlined" sx={{borderColor:COLORS.primaryColor, width:'100px', borderRadius:'22px', color: COLORS.primaryColor, '&:hover': { // Override hover styles
          color: COLORS.primaryColor, // Set hover background color to transparent
          }, visibility: userData?.userType == 'seller' ? 'visible' : 'hidden'}} 
          onClick={()=> navigate('/post-ad')}
          >Sell</Button>

           {/* {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}  */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default BottomNavigationBar;