// ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import ResponsiveAppBar from '../components/NavBar';
import BottomNavigationBar from '../components/BottomNavigation';
import AllCategoriesNav from '../components/AllCategoriesNav';
import Footer from '../components/Footer';



const ProtectedRoute = ({ redirectPath = '/', children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  console.log('isAuthenticated:', isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : (
        <>
        <ResponsiveAppBar/>
        <BottomNavigationBar/>
        <AllCategoriesNav/>
        <Outlet />
        <Footer/>
        </>);
};

export default ProtectedRoute;
