
const Banner=() =>{

    return(
        <div style={{padding:'16px'}}>
        <img src="/images/home-banner.png" alt="MMP" width={'100%'} height={268} />
        </div>
    )
}

export default Banner;