import HorizontalCarousel from '../CategoriesHorizontal';
const CategorySection = ( {categoryName, products}) => {

    return (
    <div style={{marginBottom:32}}>
        <p style={{maxWidth: 'fit-content', marginLeft: 20, fontWeight:'bold'}}>{categoryName}</p>
        <div style={{marginLeft:32, marginRight:32}}>
        <HorizontalCarousel products={products}/>
        </div>
    </div>
    )
}

export default CategorySection;