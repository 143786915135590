// import {appImages} from '../../globals/utilities';

export const profilePic =
  'https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png';

export const categories = [
  {
    id: 1,
    name: '1st stage of construction',
    image: 'https://selfbuild.ie/wp-content/uploads/2019/03/Concrete1.jpg',
  },
  {
    id: 2,
    name: '2nd stage of construction',
    image:
      'https://i0.wp.com/contractormag.co.nz/wp-content/uploads/2017/12/p22-Kapiti-Expressway-770x470.jpg?fit=770%2C470&ssl=1',
  },
  {
    id: 3,
    name: '3rd stage of construction',
    image:
      'https://proest.com/wp-content/uploads/2021/03/stages-of-construction-1024x493.jpg',
  },

  {
    id: 4,
    name: 'Machinery',
    image:
      'https://images.unsplash.com/photo-1474674556023-efef886fa147?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
  },
  {
    id: 5,
    name: 'Professionals',
    image:
      'https://st3.depositphotos.com/3555379/19033/i/450/depositphotos_190335120-stock-photo-industrial-workshop-industrial-products.jpg',
  },
  {
    id: 6,
    name: 'Technical Tools',
    image:
      'https://www.lincolntech.edu/sites/default/files/styles/960x_webp_/public/uploads/2016/07/CR359_EEST-Bag_0716_blog.jpg.webp?itok=hBITEYnA',
  },
  {
    id: 7,
    name: 'PreFabricated',
    image:
      'https://images.adsttc.com/media/images/5f8c/889d/63c0/17d6/a100/0414/slideshow/jesus_granada.jpg?1603045524',
  },
];
export const Subcategories = [
  {
    id: 1,
    name: 'Cement',
    image:
      'https://i0.wp.com/theconstructor.org/wp-content/uploads/2018/08/Blast-furnace-slag-cement.jpg?fit=1280%2C720&ssl=1',
  },
  {
    id: 2,
    name: 'Iron rods',
    image:
      'https://media.istockphoto.com/photos/many-bundles-of-steel-bar-picture-id908678266?b=1&k=20&m=908678266&s=170667a&w=0&h=_lKLyiVAeIMDkYTX8rRAVFzBM3L7Czvep64USfHYYjc=',
  },
  {
    id: 3,
    name: 'Bricks',
    image:
      'https://thumbs.dreamstime.com/b/red-bricks-photos-taken-some-construction-site-52215147.jpg',
  },
  {
    id: 4,
    name: 'Sand',
    image:
      'https://images.pexels.com/photos/1478450/pexels-photo-1478450.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
  },
  {
    id: 5,
    name: 'Gravel',
    image:
      'https://images.unsplash.com/photo-1557533618-abdc7b3ae7b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGdyYXZlbHxlbnwwfHwwfHw%3D&w=1000&q=80',
  },
  {
    id: 6,
    name: 'Wood',
    image:
      'https://dreamcivil.com/wp-content/uploads/2022/07/ezgif.com-gif-maker-50.webp',
  },
  {
    id: 1,
    name: 'Cement',
    image:
      'https://cementconcrete.org/wp-content/uploads/2019/11/Storage-of-Cement-min-1024x765.jpg',
  },
  {
    id: 2,
    name: 'Iron rods',
    image:
      'https://t3.ftcdn.net/jpg/00/41/80/98/360_F_41809835_3V0EhIZQQzwOxKKHyP2PLFwWqxpAWwCP.jpg',
  },
  {
    id: 3,
    name: 'Bricks',
    image:
      'https://www.civilclick.com/wp-content/uploads/2017/11/TYPES-OF-BRICKS.jpg',
  },
  {
    id: 4,
    name: 'Sand',
    image:
      'https://2.bp.blogspot.com/-i39C4-QEEt8/WwhXP7lzMsI/AAAAAAAAE50/dpG_zYvL5Gs0H0YV-nj39Mtx7vm3AnkxACLcBGAs/s1600/heap%2Bof%2Bsand.JPG',
  },
  {
    id: 5,
    name: 'Gravel',
    image: 'https://ebyland.com/wp-content/uploads/2019/04/57s_gravel.jpg',
  },
  {
    id: 6,
    name: 'Wood',
    image: 'https://cdn.britannica.com/85/161685-050-858990B5/Lumber-piles.jpg',
  },
];


export const professionList = [
  'Building Contractors',
  'Architects',
  'Building Engineers',
  'Structural Engineers',
  'Masson',
  'Carpenter',
  'Glass Fixers',
  'Aluminum Fixers',
  'Plumber',
  'Tiles Fixer',
  'Wood Polisher',
  'Paint Job',
  'Electrician',
  'Civil engineers',
  'Interior designer',
  'welder',
];

export const machineryList = [
  'Digging Machinery',
  'Road Rollers',
  'Cranes',
  'Excavators',
  'Gradders',
  'Planners',

  'Boring machines',
  'Drilling machines',
  'Compressor',
  'Compact excavator',
  'Dragline excavator',
  'Aerial work platform',
  'Paver',
  'Concrete mixer',
  'Tracked loader',
  'Tower crane',
  'Loaders',
  'Bulldozers',
  'Compactors',
  'Backhoe loader',
  'Skid steer loader',
  'Dump truck',
  'Trencher',
  'Backhoe',
  'Telescopic handler',
  'Wheel tractor scraper',
  'Forklift',
  'Generators',
];

export const preFebricatedList = [
  'Sheds',
  'Homes',
  'Halls',
  'Offices',
  'Concrete pre fabricated',
  'Steel prefabricated buildings',
  'Tensile shades',
];

export const techniqalTollsList = ['Hardware items', 'Hardware tools'];

export const firstStageList = [
  'Bricks',
  'blocks',
  'Sand',
  'Gravel',
  'stones',
  'cement',
  'concerete',
  'Steel',
  'Iron rods',
  'Iron pipes',
  'Raw wood',
  'Shetring sheets',
  'Fibre reinforced plastic',
  'Aggregate',
  'mud',
];

export const secondStageList = [
  'Electric pipes',
  'Electric wires',
  'Electric fittings',
  'Electric accessories',
  'Marble',
  'Floor tiles',
  'Granite tiles',
  'Wooden doors',
  'PVC doors',
  'Aluminum Doors',
  'Glass doors',
  'Fiberglass doors',
  'Glass ',
  'Wood',
  'Wood work',
  'Timber',
  'Iron sheets',
  'Iron pipes',
  'Steel pipes',
  'Door locks',
  'Door handles',
  'Kitchen tiles',
  'Floor tiles',
  'Kitchen cabinets',
  'Kitchen accessories',
  'Kitchen sinks',
  'Kitchen ducts',
  'Ready kitchen',
  'PVC Pipes',
  'PVC Fittings',
  'PPRC Pipes',
  'PPRC fittings',
  'UPVC Pipes',
  'UPVC fittings',
  'thermoplastic',
  'Bathroom tiles',
  'Bathroom fittings',
  'Bathroom accessories',
  'Bathroom Sanitary',
  'Bathroom tubs',
  'Plywood etc',
];

export const thirdStageList = [
  'House furniture',
  'Office furniture',
  'Hospital Furniture',
  'Plastic furniture',
  'Steel furniture',
  'Cielings',
  'paneling',
  'Carpets',
  'cloth',
  'Rugs',
  'Curtains/blinds',
  'Cloth curtain',
  'Fanous/ chandler',
  'Electronic items',
  'Electric items',
  'Mattresses',
  'Solar system and Accessories',
  'Security system',
  'Electric fencing',
  'Security Camera CCTV',
  'CNC Machine for wood',
  'CNC machine for iron',
  'Mud',
  'Elevators',
  'escalators',
  'Garden items',
  'Plants',
  'Grass',
  'Water filter plants',
  'Wall paper',
  'Vinyl floor',
  'Wooden floor',
  'Ceramic floors',
  'Ceramic walls',
  'DC Electronics',
  'DC Electrical item',
  'Paint',
  'heating and cooling system',
  'Decoration items',
  'Batteries',
];

export const subCategoriesList = [
  {
    name: '1st stage of construction',
    list: firstStageList
  },
  {
    name: '2nd stage of construction',
    list: secondStageList
  },
  {
    name: '3rd stage of construction',
    list: thirdStageList
  },
  {
    name: 'Machinery',
    list: machineryList
  },
  {
    name: 'Professionals',
    list: professionList
  },
  {
    name: 'Technical Tools',
    list: techniqalTollsList
  },
  {
    name: 'PreFabricated',
    list: preFebricatedList
  },
];

// ! do not delete this
// ! we are using this in app for future
export const termAndConditionTetx =
  'The platform MMPSites is just for the purpose of information of different sellers at this platform' +
  ' for convenience of public. The information available is totally provided by the sellers without any ' +
  ' omission or addition. Any transactions between the sellers and buyers are totally at their own risk and have ' +
  'nothing to do with the platform. Make every effort to verify the information of sellers the platform hold absolutely ' +
  'no responsibility for false and fabricated information. The platform does not verify any information. Any person or' +
  ' party providing false fabricated forged information will be prosecuted as per the law. This platform does not accept' +
  ' any liability of false information provided by third party. In case of any dispute between buyers and sellers the platform' +
  ' do not hold any responsibility and in any way the jurisdiction of Rawalpindi will be entertained as the head office of the' +
  ' platform is situated in Rawalpindi. Our platform do not charge any kind of commission from anyone and we do not sell our users' +
  ' information to anyone our sole purpose is to facilitate and promote the construction business and provide information about the' +
  ' person connected to different fields of construction industry let the both ends meet.';

export const aboutUsText =
  'The platform MMPSites is just for the purpose of information of different sellers at this platform' +
  ' for convenience of public. The information available is totally provided by the sellers without any ' +
  ' omission or addition. Any transactions between the sellers and buyers are totally at their own risk and have ' +
  'nothing to do with the platform. Make every effort to verify the information of sellers the platform hold absolutely ' +
  'no responsibility for false and fabricated information. The platform does not verify any information. Any person or' +
  ' party providing false fabricated forged information will be prosecuted as per the law. This platform does not accept' +
  ' any liability of false information provided by third party. In case of any dispute between buyers and sellers the platform' +
  ' do not hold any responsibility and in any way the jurisdiction of Rawalpindi will be entertained as the head office of the' +
  ' platform is situated in Rawalpindi. Our platform do not charge any kind of commission from anyone and we do not sell our users' +
  ' information to anyone our sole purpose is to facilitate and promote the construction business and provide information about the' +
  ' person connected to different fields of construction industry let the both ends meet.';

// ! do not delete this
// ! we are using this in app for future
// export const allCities = [
//   {
//     city: 'Karachi',
//   },
//   {
//     city: 'Lahore',
//   },
//   {
//     city: 'Faisalabad',
//   },
//   {
//     city: 'Rawalpindi',
//   },
//   {
//     city: 'Gujranwala',
//   },
//   {
//     city: 'Peshawar',
//   },
//   {
//     city: 'Multan',
//   },
//   {
//     city: 'Saidu Sharif',
//   },
//   {
//     city: 'Hyderabad City',
//   },
//   {
//     city: 'Islamabad',
//   },
//   {
//     city: 'Quetta',
//   },
//   {
//     city: 'Bahawalpur',
//   },
//   {
//     city: 'Sargodha',
//   },
//   {
//     city: 'Sialkot City',
//   },
//   {
//     city: 'Sukkur',
//   },
//   {
//     city: 'Larkana',
//   },
//   {
//     city: 'Chiniot',
//   },
//   {
//     city: 'Shekhupura',
//   },
//   {
//     city: 'Jhang City',
//   },
//   {
//     city: 'Dera Ghazi Khan',
//   },
//   {
//     city: 'Gujrat',
//   },
//   {
//     city: 'Rahimyar Khan',
//   },
//   {
//     city: 'Kasur',
//   },
//   {
//     city: 'Mardan',
//   },
//   {
//     city: 'Mingaora',
//   },
//   {
//     city: 'Nawabshah',
//   },
//   {
//     city: 'Sahiwal',
//   },
//   {
//     city: 'Mirpur Khas',
//   },
//   {
//     city: 'Okara',
//   },
//   {
//     city: 'Mandi Burewala',
//   },
//   {
//     city: 'Jacobabad',
//   },
//   {
//     city: 'Saddiqabad',
//   },
//   {
//     city: 'Kohat',
//   },
//   {
//     city: 'Muridke',
//   },
//   {
//     city: 'Muzaffargarh',
//   },
//   {
//     city: 'Khanpur',
//   },
//   {
//     city: 'Gojra',
//   },
//   {
//     city: 'Mandi Bahauddin',
//   },
//   {
//     city: 'Abbottabad',
//   },
//   {
//     city: 'Turbat',
//   },
//   {
//     city: 'Dadu',
//   },
//   {
//     city: 'Bahawalnagar',
//   },
//   {
//     city: 'Khuzdar',
//   },
//   {
//     city: 'Pakpattan',
//   },
//   {
//     city: 'Tando Allahyar',
//   },
//   {
//     city: 'Ahmadpur East',
//   },
//   {
//     city: 'Vihari',
//   },
//   {
//     city: 'Jaranwala',
//   },
//   {
//     city: 'New Mirpur',
//   },
//   {
//     city: 'Kamalia',
//   },
//   {
//     city: 'Kot Addu',
//   },
//   {
//     city: 'Nowshera',
//   },
//   {
//     city: 'Swabi',
//   },
//   {
//     city: 'Khushab',
//   },
//   {
//     city: 'Dera Ismail Khan',
//   },
//   {
//     city: 'Chaman',
//   },
//   {
//     city: 'Charsadda',
//   },
//   {
//     city: 'Kandhkot',
//   },
//   {
//     city: 'Chishtian',
//   },
//   {
//     city: 'Hasilpur',
//   },
//   {
//     city: 'Attock Khurd',
//   },
//   {
//     city: 'Muzaffarabad',
//   },
//   {
//     city: 'Mianwali',
//   },
//   {
//     city: 'Jalalpur Jattan',
//   },
//   {
//     city: 'Bhakkar',
//   },
//   {
//     city: 'Zhob',
//   },
//   {
//     city: 'Dipalpur',
//   },
//   {
//     city: 'Kharian',
//   },
//   {
//     city: 'Mian Channun',
//   },
//   {
//     city: 'Bhalwal',
//   },
//   {
//     city: 'Jamshoro',
//   },
//   {
//     city: 'Pattoki',
//   },
//   {
//     city: 'Harunabad',
//   },
//   {
//     city: 'Kahror Pakka',
//   },
//   {
//     city: 'Toba Tek Singh',
//   },
//   {
//     city: 'Samundri',
//   },
//   {
//     city: 'Shakargarh',
//   },
//   {
//     city: 'Sambrial',
//   },
//   {
//     city: 'Shujaabad',
//   },
//   {
//     city: 'Hujra Shah Muqim',
//   },
//   {
//     city: 'Kabirwala',
//   },
//   {
//     city: 'Mansehra',
//   },
//   {
//     city: 'Lala Musa',
//   },
//   {
//     city: 'Chunian',
//   },
//   {
//     city: 'Nankana Sahib',
//   },
//   {
//     city: 'Bannu',
//   },
//   {
//     city: 'Pasrur',
//   },
//   {
//     city: 'Timargara',
//   },
//   {
//     city: 'Parachinar',
//   },
//   {
//     city: 'Chenab Nagar',
//   },
//   {
//     city: 'Gwadar',
//   },
//   {
//     city: 'Abdul Hakim',
//   },
//   {
//     city: 'Hassan Abdal',
//   },
//   {
//     city: 'Tank',
//   },
//   {
//     city: 'Hangu',
//   },
//   {
//     city: 'Risalpur Cantonment',
//   },
//   {
//     city: 'Karak',
//   },
//   {
//     city: 'Kundian',
//   },
//   {
//     city: 'Umarkot',
//   },
//   {
//     city: 'Chitral',
//   },
//   {
//     city: 'Dainyor',
//   },
//   {
//     city: 'Kulachi',
//   },
//   {
//     city: 'Kalat',
//   },
//   {
//     city: 'Kotli',
//   },
//   {
//     city: 'Gilgit',
//   },
//   {
//     city: 'Narowal',
//   },
//   {
//     city: 'Khairpur Mir’s',
//   },
//   {
//     city: 'Khanewal',
//   },
//   {
//     city: 'Jhelum',
//   },
//   {
//     city: 'Haripur',
//   },
//   {
//     city: 'Shikarpur',
//   },
//   {
//     city: 'Rawala Kot',
//   },
//   {
//     city: 'Hafizabad',
//   },
//   {
//     city: 'Lodhran',
//   },
//   {
//     city: 'Malakand',
//   },
//   {
//     city: 'Attock City',
//   },
//   {
//     city: 'Batgram',
//   },
//   {
//     city: 'Matiari',
//   },
//   {
//     city: 'Ghotki',
//   },
//   {
//     city: 'Naushahro Firoz',
//   },
//   {
//     city: 'Alpurai',
//   },
//   {
//     city: 'Bagh',
//   },
//   {
//     city: 'Daggar',
//   },
//   {
//     city: 'Leiah',
//   },
//   {
//     city: 'Tando Muhammad Khan',
//   },
//   {
//     city: 'Chakwal',
//   },
//   {
//     city: 'Badin',
//   },
//   {
//     city: 'Lakki',
//   },
//   {
//     city: 'Rajanpur',
//   },
//   {
//     city: 'Dera Allahyar',
//   },
//   {
//     city: 'Shahdad Kot',
//   },
//   {
//     city: 'Pishin',
//   },
//   {
//     city: 'Sanghar',
//   },
//   {
//     city: 'Upper Dir',
//   },
//   {
//     city: 'Thatta',
//   },
//   {
//     city: 'Dera Murad Jamali',
//   },
//   {
//     city: 'Kohlu',
//   },
//   {
//     city: 'Mastung',
//   },
//   {
//     city: 'Dasu',
//   },
//   {
//     city: 'Athmuqam',
//   },
//   {
//     city: 'Loralai',
//   },
//   {
//     city: 'Barkhan',
//   },
//   {
//     city: 'Musa Khel Bazar',
//   },
//   {
//     city: 'Ziarat',
//   },
//   {
//     city: 'Gandava',
//   },
//   {
//     city: 'Sibi',
//   },
//   {
//     city: 'Dera Bugti',
//   },
//   {
//     city: 'Eidgah',
//   },
//   {
//     city: 'Uthal',
//   },
//   {
//     city: 'Khuzdar',
//   },
//   {
//     city: 'Chilas',
//   },
//   {
//     city: 'Panjgur',
//   },
//   {
//     city: 'Gakuch',
//   },
//   {
//     city: 'Qila Saifullah',
//   },
//   {
//     city: 'Kharan',
//   },
//   {
//     city: 'Aliabad',
//   },
//   {
//     city: 'Awaran',
//   },
//   {
//     city: 'Dalbandin',
//   },

//   // ================

//   {
//     city: 'Taxila',
//   },
//   {
//     city: 'Gujarkhan',
//   },
//   {
//     city: 'Gawader',
//   },
//   {
//     city: 'kalam',
//   },
//   {
//     city: 'Rinala khurd',
//   },
//   {
//     city: 'Raiwind',
//   },
//   {
//     city: 'Samundri',
//   },
//   {
//     city: 'Arifwala',
//   },
//   {
//     city: 'Burewala',
//   },
//   {
//     city: 'Vehari',
//   },
//   {
//     city: 'Mailsi',
//   },
//   {
//     city: 'Kashmore',
//   },
//   {
//     city: 'Sui',
//   },
//   {
//     city: 'kahuta',
//   },
//   {
//     city: 'Wah',
//   },
//   {
//     city: 'Fatehjang',
//   },
//   {
//     city: 'Kallarkahar',
//   },
//   {
//     city: 'Talagang',
//   },
//   {
//     city: 'Loralai',
//   },
//   {
//     city: 'Lalian',
//   },
//   {
//     city: 'Pirmahal',
//   },
//   {
//     city: 'Dunyapur',
//   },
// ];
// export const PopularCities = [
//   {
//     city: 'Karachi',
//     image: appImages.karachi,
//   },
//   {
//     city: 'Faisalabad',
//     image: appImages.faisalabad,
//   },
//   {
//     city: 'Islamabad',
//     image: appImages.islamabad,
//   },
//   {
//     city: 'Lahore',
//     image: appImages.lahore,
//   },
//   {
//     city: 'Peshawar',
//     image: appImages.peshawar,
//   },
//   {
//     city: 'Quetta',
//     image: appImages.quetta,
//   },
// ];

export const allCities = [
  {
    label: 'Karachi',
  },
  {
    label: 'Lahore',
  },
  {
    label: 'Faisalabad',
  },
  {
    label: 'Rawalpindi',
  },
  {
    label: 'Gujranwala',
  },
  {
    label: 'Peshawar',
  },
  {
    label: 'Multan',
  },
  {
    label: 'Saidu Sharif',
  },
  {
    label: 'Hyderabad label',
  },
  {
    label: 'Islamabad',
  },
  {
    label: 'Quetta',
  },
  {
    label: 'Bahawalpur',
  },
  {
    label: 'Sargodha',
  },
  {
    label: 'Sialkot label',
  },
  {
    label: 'Sukkur',
  },
  {
    label: 'Larkana',
  },
  {
    label: 'Chiniot',
  },
  {
    label: 'Shekhupura',
  },
  {
    label: 'Jhang label',
  },
  {
    label: 'Dera Ghazi Khan',
  },
  {
    label: 'Gujrat',
  },
  {
    label: 'Rahimyar Khan',
  },
  {
    label: 'Kasur',
  },
  {
    label: 'Mardan',
  },
  {
    label: 'Mingaora',
  },
  {
    label: 'Nawabshah',
  },
  {
    label: 'Sahiwal',
  },
  {
    label: 'Mirpur Khas',
  },
  {
    label: 'Okara',
  },
  {
    label: 'Mandi Burewala',
  },
  {
    label: 'Jacobabad',
  },
  {
    label: 'Saddiqabad',
  },
  {
    label: 'Kohat',
  },
  {
    label: 'Muridke',
  },
  {
    label: 'Muzaffargarh',
  },
  {
    label: 'Khanpur',
  },
  {
    label: 'Gojra',
  },
  {
    label: 'Mandi Bahauddin',
  },
  {
    label: 'Abbottabad',
  },
  {
    label: 'Turbat',
  },
  {
    label: 'Dadu',
  },
  {
    label: 'Bahawalnagar',
  },
  {
    label: 'Khuzdar',
  },
  {
    label: 'Pakpattan',
  },
  {
    label: 'Tando Allahyar',
  },
  {
    label: 'Ahmadpur East',
  },
  {
    label: 'Vihari',
  },
  {
    label: 'Jaranwala',
  },
  {
    label: 'New Mirpur',
  },
  {
    label: 'Kamalia',
  },
  {
    label: 'Kot Addu',
  },
  {
    label: 'Nowshera',
  },
  {
    label: 'Swabi',
  },
  {
    label: 'Khushab',
  },
  {
    label: 'Dera Ismail Khan',
  },
  {
    label: 'Chaman',
  },
  {
    label: 'Charsadda',
  },
  {
    label: 'Kandhkot',
  },
  {
    label: 'Chishtian',
  },
  {
    label: 'Hasilpur',
  },
  {
    label: 'Attock Khurd',
  },
  {
    label: 'Muzaffarabad',
  },
  {
    label: 'Mianwali',
  },
  {
    label: 'Jalalpur Jattan',
  },
  {
    label: 'Bhakkar',
  },
  {
    label: 'Zhob',
  },
  {
    label: 'Dipalpur',
  },
  {
    label: 'Kharian',
  },
  {
    label: 'Mian Channun',
  },
  {
    label: 'Bhalwal',
  },
  {
    label: 'Jamshoro',
  },
  {
    label: 'Pattoki',
  },
  {
    label: 'Harunabad',
  },
  {
    label: 'Kahror Pakka',
  },
  {
    label: 'Toba Tek Singh',
  },
  {
    label: 'Samundri',
  },
  {
    label: 'Shakargarh',
  },
  {
    label: 'Sambrial',
  },
  {
    label: 'Shujaabad',
  },
  {
    label: 'Hujra Shah Muqim',
  },
  {
    label: 'Kabirwala',
  },
  {
    label: 'Mansehra',
  },
  {
    label: 'Lala Musa',
  },
  {
    label: 'Chunian',
  },
  {
    label: 'Nankana Sahib',
  },
  {
    label: 'Bannu',
  },
  {
    label: 'Pasrur',
  },
  {
    label: 'Timargara',
  },
  {
    label: 'Parachinar',
  },
  {
    label: 'Chenab Nagar',
  },
  {
    label: 'Gwadar',
  },
  {
    label: 'Abdul Hakim',
  },
  {
    label: 'Hassan Abdal',
  },
  {
    label: 'Tank',
  },
  {
    label: 'Hangu',
  },
  {
    label: 'Risalpur Cantonment',
  },
  {
    label: 'Karak',
  },
  {
    label: 'Kundian',
  },
  {
    label: 'Umarkot',
  },
  {
    label: 'Chitral',
  },
  {
    label: 'Dainyor',
  },
  {
    label: 'Kulachi',
  },
  {
    label: 'Kalat',
  },
  {
    label: 'Kotli',
  },
  {
    label: 'Gilgit',
  },
  {
    label: 'Narowal',
  },
  {
    label: 'Khairpur Mir’s',
  },
  {
    label: 'Khanewal',
  },
  {
    label: 'Jhelum',
  },
  {
    label: 'Haripur',
  },
  {
    label: 'Shikarpur',
  },
  {
    label: 'Rawala Kot',
  },
  {
    label: 'Hafizabad',
  },
  {
    label: 'Lodhran',
  },
  {
    label: 'Malakand',
  },
  {
    label: 'Attock label',
  },
  {
    label: 'Batgram',
  },
  {
    label: 'Matiari',
  },
  {
    label: 'Ghotki',
  },
  {
    label: 'Naushahro Firoz',
  },
  {
    label: 'Alpurai',
  },
  {
    label: 'Bagh',
  },
  {
    label: 'Daggar',
  },
  {
    label: 'Leiah',
  },
  {
    label: 'Tando Muhammad Khan',
  },
  {
    label: 'Chakwal',
  },
  {
    label: 'Badin',
  },
  {
    label: 'Lakki',
  },
  {
    label: 'Rajanpur',
  },
  {
    label: 'Dera Allahyar',
  },
  {
    label: 'Shahdad Kot',
  },
  {
    label: 'Pishin',
  },
  {
    label: 'Sanghar',
  },
  {
    label: 'Upper Dir',
  },
  {
    label: 'Thatta',
  },
  {
    label: 'Dera Murad Jamali',
  },
  {
    label: 'Kohlu',
  },
  {
    label: 'Mastung',
  },
  {
    label: 'Dasu',
  },
  {
    label: 'Athmuqam',
  },
  {
    label: 'Loralai',
  },
  {
    label: 'Barkhan',
  },
  {
    label: 'Musa Khel Bazar',
  },
  {
    label: 'Ziarat',
  },
  {
    label: 'Gandava',
  },
  {
    label: 'Sibi',
  },
  {
    label: 'Dera Bugti',
  },
  {
    label: 'Eidgah',
  },
  {
    label: 'Uthal',
  },
  {
    label: 'Khuzdar',
  },
  {
    label: 'Chilas',
  },
  {
    label: 'Panjgur',
  },
  {
    label: 'Gakuch',
  },
  {
    label: 'Qila Saifullah',
  },
  {
    label: 'Kharan',
  },
  {
    label: 'Aliabad',
  },
  {
    label: 'Awaran',
  },
  {
    label: 'Dalbandin',
  },

  // ================

  {
    label: 'Taxila',
  },
  {
    label: 'Gujarkhan',
  },
  {
    label: 'Gawader',
  },
  {
    label: 'kalam',
  },
  {
    label: 'Rinala khurd',
  },
  {
    label: 'Raiwind',
  },
  {
    label: 'Samundri',
  },
  {
    label: 'Arifwala',
  },
  {
    label: 'Burewala',
  },
  {
    label: 'Vehari',
  },
  {
    label: 'Mailsi',
  },
  {
    label: 'Kashmore',
  },
  {
    label: 'Sui',
  },
  {
    label: 'kahuta',
  },
  {
    label: 'Wah',
  },
  {
    label: 'Fatehjang',
  },
  {
    label: 'Kallarkahar',
  },
  {
    label: 'Talagang',
  },
  {
    label: 'Loralai',
  },
  {
    label: 'Lalian',
  },
  {
    label: 'Pirmahal',
  },
  {
    label: 'Dunyapur',
  },
];