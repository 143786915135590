// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// const productList = [
//   { id: 1, title: 'Product 1', description: 'Description for Product 1', image: '/images/product-image.png' },
//   { id: 2, title: 'Product 2', description: 'Description for Product 2', image: '/images/product-image.png' },
//   { id: 3, title: 'Product 3', description: 'Description for Product 3', image: '/images/product-image.png' },
//   { id: 4, title: 'Product 4', description: 'Description for Product 3', image: '/images/product-image.png' },
//   { id: 5, title: 'Product 5', description: 'Description for Product 3', image: '/images/product-image.png' },
//   { id: 6, title: 'Product 6', description: 'Description for Product 3', image: '/images/product-image.png' },
//   { id: 7, title: 'Product 7', description: 'Description for Product 3', image: '/images/product-image.png' },
//   // Add more products here

// ];

// export default function HorizontalCarousel() {
//   const settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 2,
//     autoPlay: false,
//     prevArrow: <IconButton><ArrowBackIcon /></IconButton>,
//     nextArrow: <IconButton><ArrowForwardIcon /></IconButton>,
//   };

//   return (
//     <div style={{ width: '100%', overflow: 'hidden', background:'#fff' }}>
//       <Slider {...settings}>
//         {productList.map((product) => (
//           <div key={product.id} style={{ width: '280px', margin: '0 8px' }}>
//             <Card sx={{ maxWidth: 280 }}>
//               <CardMedia component="img" height="140" image={product.image} alt={product.title} />
//               <CardContent>
//                 <Typography gutterBottom variant="h5" component="div">
//                   {product.title}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   {product.description}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// }

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import COLORS from '../../constants/colors';

const productList = [
  { id: 1, title: 'Product 1', description: 'Description for Product 1', image: '/images/product-image.png' },
  { id: 2, title: 'Product 2', description: 'Description for Product 2', image: '/images/product-image.png' },
  { id: 3, title: 'Product 3', description: 'Description for Product 3', image: '/images/product-image.png' },
  { id: 4, title: 'Product 4', description: 'Description for Product 4', image: '/images/product-image.png' },
  { id: 5, title: 'Product 5', description: 'Description for Product 5', image: '/images/product-image.png' },
  { id: 6, title: 'Product 6', description: 'Description for Product 6', image: '/images/product-image.png' },
  { id: 7, title: 'Product 7', description: 'Description for Product 7', image: '/images/product-image.png' },
];

export default function HorizontalCarousel({products}) {
    const navigate= useNavigate()
  const settings = {
    dots: false,
    infinite: false,
    // speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: false,
    // prevArrow: <IconButton><ArrowBackIcon /></IconButton>,
    // nextArrow: <IconButton><ArrowForwardIcon /></IconButton>,
  };

  return (
    <div style={{ width: '100%'}}>
      <Slider {...settings}> 
        {products.map((product) => (
          <div key={product.id} style={{maxWidth:'fit-content'}}>
            <Card sx={{ maxWidth: 280, height: 300, marginLeft: 2, marginRight: 2, cursor:'pointer' }} onClick={() =>{navigate(`/product-detail/${product.id}`,  { scrollOptions: { top: 0 } })}}>
              <CardMedia component="img" height="140" image={product.thumbnail} alt={product.title} />
              <CardContent sx={{paddingBottom:'8px'}}>
                <Typography gutterBottom variant="h6" component="div" color={COLORS.primaryColor} fontWeight={'bold'} width={'fit-content'}>
                {product.title}
                </Typography>
                <Typography gutterBottom variant="body2" component="div" fontSize={18} color={COLORS.secondaryColor} width={'fit-content'} sx={{ maxHeight: '4em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                RS: {product.price}
                </Typography>
                {/* <Typography variant="body2" color="text.secondary" width={'fit-content'}>
                 
                </Typography> */}
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
}
