import React from 'react'
import './styles.css'; // Import CSS file for component-specific styles
import { useNavigate } from 'react-router-dom';

const ActivePlanPage = () => {


    const navigate= useNavigate();

    const handleChangePlan = () => {
        navigate('/subscribe-plan');
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '24px', paddingBottom:'24px' }}>

            <div style={{
                width: 'fit-content', height: '44px', fontWeight: 600, fontSize: '32px', marginLeft: '24px'
            }}>
                ACTIVE PLAN
            </div>

            <div className='active-plan-details'>
                <div>
                    <p className='subscription-text'>Monthly Subscription Active</p>
                    <p className='subscription-text'>Expiry Date : 23-11-2021</p>
                    <div style={{ display: 'flex', justifyContent: 'space-around', gap: '30px', marginTop: '65px' }}>
                        <div className='action-button'>Renew</div>
                        <div className='action-button'>Unsubscribe</div>
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '35px' }}>
                <div className='change-plan-button ' onClick={handleChangePlan}>Change Plan Now</div>
            </div>

        </div >
    )
}

export default ActivePlanPage;

