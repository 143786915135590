const PATHS = {
    HOME: "/",
    ABOUTUS: "/about-us",
    CONTACT: "/contact-us",
    SIGN_IN: "/signin",
    SIGN_UP: "/signup",
    SIGNUP_TYPE:'/signup-type',
    SIGNUP_SELLER:'/signup-seller',
    PRODUCT_DETAIL: "/product-detail/:productId",
    CATEGORY_PAGE: "/category/:categoryId",
    ACTIVE_PLAN: "/active-plan",
    SUBSCRIPTION:'/subscribe-plan',
    PAYMENT:'/payment',
    POST_AD:'/post-ad',
  };
  export default PATHS;
  