
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress } from '@mui/material';

import { toast } from 'react-toastify';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { categories, subCategoriesList } from '../DataSet';
import { storage } from '../config/firebase';
import { postData } from '../services/products';

const PostAd = () => {
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userData')));
    const [thumbnails, setThumbnails] = useState([]);
    const [imagesUrls, setImagesUrls] = useState([]);
    const [showingAll, setShowingAll] = useState(true);
    const [adTitle, setAdTitle] = useState('');
    const [adDescription, setAdDescription] = useState('');
    const [price, setPrice] = useState('');
    const [location, setLocation] = useState(userData?.location || '');
    const [name, setName] = useState(userData?.name || '');
    const [phoneNumber, setPhoneNumber] = useState( userData?.phoneNumber || '');
    const [selectedCount, setSelectedCount] = useState(0); // State for selected picture count
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [mainCategory, setMainCategory] = useState('');
    const [actualMainCategory, setActualMainCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [subCategoryArray, setSubCategoryArray] = useState([]);
    const [isMainCategorySelected, setIsMainCategorySelected] = useState(false);

    const [error, setError] = useState('');


    const handleFileInputChange = async (e) => {
        setLoading(true);
        const files = e.target.files;
        console.log('files:',files);
        // console.log('fileName:', files[0].name)
        handleInputChange()
        const images = [];
        setSelectedCount(files.length); // Update selected picture count

        for (let i = 0; i < Math.min(files.length, 6); i++) { // Limit to first 6 files
            const reader = new FileReader();
            reader.onload = (e) => {
                images.push(e.target.result);
                if (images.length === Math.min(files.length, 6)) { // Check if all images are loaded
                    setThumbnails(images);
                    if (files.length > 6) {
                        setShowingAll(false);
                    }
                }
            };
            reader.readAsDataURL(files[i]);
        }
        await uploadFilesToFirebase(files);
        setLoading(false);
    };

    const uploadFilesToFirebase = async (files) => {
        console.log('This function executes');
        const uploadPromises = [];
        for (let i = 0; i < Math.min(files.length, 6); i++) {
          const file = files[i];
          const fileName= Date.now()+file.name; 
          const storageRef = ref(storage, `product/${fileName}`);
          const uploadTask = uploadBytes(storageRef, file);
    
          uploadPromises.push(uploadTask.then(async (snapshot) => {
            const url = await getDownloadURL(snapshot.ref);
            return url;
          }));
        }
    
        try {
          const urls = await Promise.all(uploadPromises);
          console.log("Uploaded URLs:", urls);
          setImagesUrls(urls);
        //   await saveUrlsToFirestore(urls);
        } catch (error) {
          console.error("Error uploading files:", error);
        }
      };

    const handleSubmit = async (e) => {
        if (!actualMainCategory || !subCategory) {
            setError('Please select a category.');
            return;
        }
        e.preventDefault();
        if (!adTitle || !adDescription || !location || !name || !phoneNumber ||!price) {
            setError('Please fill in all the fields.');
            return;
        }
        if (thumbnails.length === 0) {
            setError('Please select at least one picture.');
            return;
        }
        console.log('thumbnails:', thumbnails);

        setError('');
        let id = userData?.id;
        setLoading(true);
        let obj = {
        addedBy: id,
        thumbnail: imagesUrls[0],
        images: imagesUrls,
        // colors: colorsList,
        title: adTitle,
        desc: adDescription,
        price: price,
        location: location,
        phoneNumber: phoneNumber,
        englishCategory: mainCategory,
        englishSubCategory: subCategory,
        urduCategory: '',
        urduSubCategory: '',
        likeBy: [],
        isFeatured: false,
        // coords: coord,
        };
        await postData('products', obj);
        toast.success('Your Ad posted successfully')
        // Handle form submission here, you can send data to backend or perform other actions
        console.log('Form submitted!');
        console.log({ adTitle, adDescription, location, phoneNumber, name, price, imagesUrls });
        // Clear input fields after submission
        setAdTitle('');
        setAdDescription('');
        setLocation('');
        setName('');
        setPhoneNumber('');
        setMainCategory('');
        setActualMainCategory('');
        setSubCategory('');
        setSubCategoryArray([]);
        setSelectedCount(0);
        setThumbnails([]);
        setPrice('');
        setShow(true);
        setLoading(false);
        navigate('/');
    };

    const style = {
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #027242',
        boxShadow: 24,
        p: 4,
    };

    const handleMainCategoryChange = (event) => {
        setMainCategory(event.target.value);
        console.log(event.target.value)
        let category = subCategoriesList.find((item) => item.name == event.target.value) || []
        console.log(category.list)
        setSubCategoryArray(category.list)
        setIsMainCategorySelected(true);

        // Here you can fetch subcategories based on the selected main category from backend or static data
        // For demonstration purpose, I'm assuming static subcategories
        // You can replace this with actual data retrieval from your backend
        // Example:
        // fetchSubCategories(event.target.value);
    };

    const handleSubCategoryChange = (event) => {
        setSubCategory(event.target.value);
        setActualMainCategory(mainCategory)
        setOpen(false)
        setIsMainCategorySelected(false)
        setSubCategoryArray([])
        setAdTitle(event.target.value)
        setShow(false)
    };

    const handleModelClose = () => {
        setOpen(false)
        setIsMainCategorySelected(false)
        setSubCategoryArray([])
    }

    const handleOpenModel = () => {
        setOpen(true)
    }

    const handleInputChange = () => {
        setError(''); // Clear error message when input field is focused
    };


    return (
        <div style={{padding:'20px'}}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h3>Post Your Ad</h3>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ width: '80%', border: '1px solid black', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                        <span style={{ fontWeight: 650, cursor: 'pointer' }}>Selected Category</span>
                        <div style={{ marginTop: '10px', display: 'flex' }}>
                            <span >{subCategory && `${actualMainCategory} / ${subCategory}`}</span>
                            {!show && <span style={{ marginLeft: '25px', fontWeight: 550, cursor: 'pointer' }} onClick={handleOpenModel}>Change</span>}
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            sx={{marginTop:'100px'}}
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Select Category
                                </Typography>
                                <FormControl sx={{ mt: 2, width: '100%' }}>
                                    <FormLabel>Main Category</FormLabel>
                                    <Select
                                        value={mainCategory}
                                        onChange={handleMainCategoryChange}
                                        onFocus={handleInputChange}
                                    >
                                        {categories.map((item) => (
                                            <MenuItem value={item.name}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ mt: 2, width: '100%', }}>
                                    <FormLabel>Sub Category</FormLabel>
                                    <Select
                                        value={subCategory}
                                        onChange={handleSubCategoryChange}
                                        disabled={!isMainCategorySelected}
                                        onFocus={handleInputChange}
                                    >
                                        {subCategoryArray.map((item) => (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        ))}
                                        {/* Add more sub categories as needed */}
                                    </Select>
                                </FormControl>
                                <Button onClick={handleModelClose} sx={{ mt: 2 }}>Close</Button>
                            </Box>
                        </Modal>
                    </div>
                    {show && <button style={{ marginRight: '20px', padding: '0', backgroundColor: '#027242', fontWeight: 600, color: 'white', width: '120px', height: '50px', borderRadius: '10px', border: 'none', margin: '10px', cursor: 'pointer' }} onClick={handleOpen}>Add Category</button>
                    }
                </div>

                <div style={{ width: '80%', border: '1px solid black' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', }}>
                        <h4>INCLUDE DETAILS</h4>
                        <FormControl sx={{ gridColumn: '1/-1', }}>
                            <FormLabel>Ad Title</FormLabel>
                            <Input placeholder='Enter your Ad title' value={adTitle} onChange={(e) => setAdTitle(e.target.value)} onFocus={handleInputChange} />
                        </FormControl>
                        <FormControl sx={{ gridColumn: '1/-1', marginTop: '15px' }}>
                            <FormLabel>Description</FormLabel>
                            <Input placeholder='Enter your Ad description' value={adDescription} onChange={(e) => setAdDescription(e.target.value)} onFocus={handleInputChange} />
                        </FormControl>
                        <FormControl sx={{ gridColumn: '1/-1', marginTop: '15px' }}>
                            <FormLabel>Price</FormLabel>
                            <Input placeholder='Enter price details' value={price} onChange={(e) => setPrice(e.target.value)} onFocus={handleInputChange} />
                        </FormControl>
                        <FormControl sx={{ gridColumn: '1/-1', marginTop: '15px' }}>
                            <FormLabel>Location</FormLabel>
                            <Input placeholder='Enter your location' value={location} onChange={(e) => setLocation(e.target.value)} onFocus={handleInputChange} />
                        </FormControl>

                    </div>
                </div>

                <div style={{ width: '80%', border: '1px solid black', marginTop: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                        <h4>Upload Pictures</h4>
                        <div style={{ position: 'relative' }}>
                            <input type="file" accept="image/*" multiple onChange={handleFileInputChange} style={{ display: 'none' }} id="image-upload" />
                            <label htmlFor="image-upload" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <AddPhotoAlternateIcon sx={{
                                    color: '#027242',
                                    width: '50px',
                                    height: '50px',
                                }} /> <span>Upload Image</span>
                            </label>
                        </div>
                        {selectedCount > 0 ? <span>{selectedCount} pictures selected</span> : null} {/* Display selected picture count */}
                        {showingAll ? null : <p>Only the first 6 picturesare being displayed.</p>}
                        <div style={{ display: 'flex', marginTop: '20px', flexWrap: 'wrap' }}>
                            {thumbnails.map((thumbnail, index) => (
                                <img key={index} src={thumbnail} alt={`Thumbnail ${index}`} style={{ width: '100px', height: 'auto', marginRight: '10px', marginBottom: '10px' }} />
                            ))}
                        </div>
                    </div>
                </div>

                <div style={{ width: '80%', border: '1px solid black', marginTop: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                        <h4>Review your Details</h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <FormControl sx={{ gridColumn: '1/-1', width: '45%' }}>
                                <FormLabel>Name</FormLabel>
                                <Input placeholder='Enter your name' value={name} onChange={(e) => setName(e.target.value)} onFocus={handleInputChange} />
                            </FormControl>
                            <FormControl sx={{ gridColumn: '1/-1', width: '45%' }}>
                                <FormLabel>Phone Number</FormLabel>
                                <Input placeholder='Enter your phone number' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} onFocus={handleInputChange} />
                            </FormControl></div>
                    </div>
                </div>

                {error && <p style={{ color: 'red' }}>{error}</p>}

                <Button sx={{
                    width: '120px', height: '50px', borderRadius: '10px', border: 'none', fontWeight: 600, backgroundColor: '#027242', '&:hover': { // Override hover styles
                        backgroundColor: '#027242', // Set hover background color to transparent
                      }, color: 'white', marginTop: '20px', cursor: 'pointer'
                }} onClick={handleSubmit} disabled={loading}>
                     {loading ? (
                    <CircularProgress
                        size={24}
                        style={{
                        color: 'white',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12,
                        }}
                    />
                    ) : (
                    'POST NOW'
                    )}
                </Button>

            </div>
        </div>
    );
};

export default PostAd;
