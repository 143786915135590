import * as React from 'react';
import RadioGroup from '@mui/joy/RadioGroup';
import Sheet from '@mui/joy/Sheet';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';

const plans = [
    {
        days: '15 Days',
        description: '15 Days Subscription',
        rate: '$400/15 Days'
    },
    {
        days: '1 Month',
        description: '1 Month Subscription',
        rate: '$4400/Month'
    },
    {
        days: '3 Month',
        description: '3 Month Subscription',
        rate: '$9000/Month'
    }

]
export default function SubscribePlan() {
    const [selectedPlan, setSelectedPlan] = React.useState(null);
    const navigate= useNavigate();

    const handleClick = (index) => {
        setSelectedPlan(index);
    };

    const handleButtonClick = () => {
        navigate('/payment');
    }

    return (
        <div style={{display:'flex', justifyContent:'center', paddingTop:'24px', paddingBottom: '24px'}}>
        <RadioGroup
            aria-label="platform"
            defaultValue="Website"
            overlay
            name="platform"
            sx={{
                flexDirection: 'row',
                gap: 2,
            }}
        >
            {plans.map((plan, index) => (
                <Sheet
                    key={index}
                    variant="outlined"
                    sx={{
                        borderRadius: 'md',
                        boxShadow: 'sm',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1.5,
                        p: 2,
                        minWidth: 200,
                        color: selectedPlan === index ? '#F4F4F4' : '#383838', // Change background color if selected
                        backgroundColor: selectedPlan === index ? '#027242' : '#EDEDED', // Change background color if selected
                    }}
                    onClick={() => handleClick(index)} // Handle click event

                >
                    <h3>{plan.days}</h3>
                    <Divider style={{ width: '100%', backgroundColor: selectedPlan === index ? '#F4F4F4' : '#383838' }} />
                    <p>{plan.description}</p>
                    <h3>{plan.rate}</h3>
                    <button style={{
                        width: '120px', height: '50px', borderRadius: '10px', border: 'none', cursor:'pointer', fontWeight: 600,
                        backgroundColor: selectedPlan === index ? 'white' : '#027242',
                        color: selectedPlan === index ? '#027242' : 'white'
                    }} onClick={handleButtonClick}>Get Now</button>
                </Sheet>
            ))}
        </RadioGroup>
        </div>
    );
}


