import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { categories } from '../DataSet';
import { useNavigate } from 'react-router-dom';

export default function CategoriesHorizontalList({activeCategory, setSelectedCategoryId}) {
  console.log("ACTIVE_CATEGERY:", activeCategory);
  const [selectedId, setSelectedId] = React.useState(activeCategory || 0);
  const navigate = useNavigate();

  const handleChange = (id) =>{
    setSelectedId(id);
    setSelectedCategoryId(id);
    navigate(`/category/${id}`,{scrollOptions: { top: 0 }});
  }
  

  return (
    <div style={{display:'flex', justifyContent:'center'}}>
    <List sx={{ display: 'flex', gap: '8px', borderRadius: '4px', padding: 0 }}>
        {categories.map((category) =>  (
             <ListItem sx={{ paddingY: '8px', cursor:'pointer' }} onClick={()=>handleChange(category.id)}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}> 
            <Avatar sx={{ width: 64, height: 64 }} src={category.image} />
            <div style={{display:'flex', justifyContent:'center', width:'110px'}}>
            <p style={{fontWeight: category.id == selectedId ? 'bold' : 'normal'}}>{category.name}</p>
            </div>
            </div>
            </ListItem>
        ))}
    </List>
    </div>
  );
}
